import React, { PureComponent } from 'react';
import Swiper from 'react-id-swiper';

import Html from 'Component/Html';
import Icons from 'Component/Icons';
import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductBuiltListSlider from 'Component/ProductBuiltListSlider';
import ProductLabel from 'Component/ProductLabel';
import TextPlaceholder from 'Component/TextPlaceholder';
import { noopFn } from 'Util/Common';
import { formatPrice } from 'Util/Price';
import { getBaseImage, getBuiltImage } from 'Util/Product/Extract';

import { DEFAULT_ARRANGEMENT_CATEGORY_URL } from './ProductListWithoutHotspotsWidget.config';

import './ProductListWithoutHotspotsWidget.style';

/** @namespace Pwa/Component/ProductListWithoutHotspotsWidget/Component/ProductListWithoutHotspotsWidget */
export class ProductListWithoutHotspotsWidget extends PureComponent {
    renderHeader() {
        const { title, products, currentSlide, handlePrevious, handleNext } = this.props;

        return (
            <>
                <h2>{title}</h2>
                <div block="ProductListWithoutHotspotsWidget" elem="Header">
                    {this.renderTopNavigation({
                        current: currentSlide,
                        totals: products?.length,
                        onPrevious: handlePrevious,
                        onNext: handleNext,
                    })}
                    <Link
                        mix={{ block: 'ProductListWithoutHotspotsWidget', elem: 'SeeAll' }}
                        to={DEFAULT_ARRANGEMENT_CATEGORY_URL}
                        aria-label={__('all projects')}
                    >
                        {__('all projects')}
                    </Link>
                </div>
            </>
        );
    }

    renderTopNavigation({ current = 1, totals = 1, onPrevious = noopFn, onNext = noopFn }) {
        return (
            <div block="ProductListWithoutHotspotsWidget" elem="TopNavigation">
                <button
                    block="ProductListWithoutHotspotsWidget"
                    elem="TopNavigation-Previous"
                    onClick={onPrevious}
                    disabled={current === 1}
                    aria-label={__('Previous')}
                >
                    <Icons name="arrow_left" />
                </button>
                <span>
                    {current}/{totals}
                </span>
                <button
                    block="ProductListWithoutHotspotsWidget"
                    elem="TopNavigation-Next"
                    onClick={onNext}
                    disabled={current >= totals}
                    aria-label={__('Next')}
                >
                    <Icons name="arrow_right" />
                </button>
            </div>
        );
    }

    renderSideNavigation({
        isHover = false,
        current = 1,
        totals = 1,
        onHoverEnable = noopFn,
        onHoverDisable = noopFn,
        onPrevious = noopFn,
        onNext = noopFn,
    }) {
        if (!isHover) {
            return null;
        }

        return (
            <>
                {current > 1 ? (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                        <button
                            block="ProductListWithoutHotspotsWidget"
                            elem="SideNavigation-Previous"
                            onMouseOver={onHoverEnable}
                            onMouseLeave={onHoverDisable}
                            onClick={onPrevious}
                        >
                            <Icons name="arrow_left" />
                        </button>
                    </>
                ) : null}
                {current < totals ? (
                    <>
                        {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                        <button
                            block="ProductListWithoutHotspotsWidget"
                            elem="SideNavigation-Next"
                            onMouseOver={onHoverEnable}
                            onMouseLeave={onHoverDisable}
                            onClick={onNext}
                        >
                            <Icons name="arrow_right" />
                        </button>
                    </>
                ) : null}
            </>
        );
    }

    renderInformation() {
        return (
            <div block="ProductListWithoutHotspotsWidget" elem="Product-Information">
                <h4>{__('Ready bathroom designs are waiting for you - we will adapt them to your room for FREE!')}</h4>
                <Link
                    to="/lazienka-inspiracje.html"
                    block="Button"
                    mods={{ isHollow: true }}
                    aria-label={__('Ready designs')}
                >
                    {__('Ready designs')}
                </Link>
            </div>
        );
    }

    renderGallerySmall({ name, small_image, small_image_2, url }) {
        if (!small_image && !small_image_2) {
            return (
                <div block="ProductListWithoutHotspotsWidget" elem="Product-GallerySmall" mods={{ small: true }}>
                    <div />
                    <div />
                </div>
            );
        }

        return (
            <div block="ProductListWithoutHotspotsWidget" elem="Product-GallerySmall" mods={{ small: true }}>
                {small_image ? (
                    <Link to={url} aria-label={name}>
                        <Image src={small_image} alt={name} ratio="16x9" />
                    </Link>
                ) : null}
                {small_image_2 ? (
                    <Link to={url} aria-label={name}>
                        <Image src={small_image_2} alt={name} ratio="16x9" />
                    </Link>
                ) : null}
            </div>
        );
    }

    renderGallery({ name, labels, product, url }) {
        const image = getBaseImage(product);
        const big_image = getBuiltImage(product, 'built_product_big_image');
        const small_image = getBuiltImage(product, 'built_product_small_image');
        const small_image_2 = getBuiltImage(product, 'built_product_small_image_2');

        return (
            <div block="ProductListWithoutHotspotsWidget" elem="Product-Gallery" mods={{ isBigImage: !!big_image }}>
                {big_image ? (
                    <>
                        <Link to={url} aria-label={name}>
                            <ProductLabel labels={labels} />
                            <Image src={big_image} alt={name} ratio="16x9" />
                        </Link>
                        {this.renderGallerySmall({ name, small_image, small_image_2, url })}
                    </>
                ) : (
                    <>
                        {this.renderGallerySmall({ name, small_image, small_image_2, url })}
                        <Link to={url} aria-label={name}>
                            <ProductLabel labels={labels} />
                            <Image src={image} alt={name} ratio="16x9" />
                        </Link>
                    </>
                )}
            </div>
        );
    }

    renderBuiltProductList(products = []) {
        return (
            <ProductBuiltListSlider
                title={__('Products used in this bathroom project')}
                products={products?.map(({ product }) => product)}
            />
        );
    }

    renderProduct({ uid, labels, listing_name, name, url, short_description, items, ...product } = {}) {
        const {
            handleHoverDisable,
            handleHoverEnable,
            device: { isMobile, isTablet },
        } = this.props;

        const {
            price_range: {
                maximum_price: { regular_price: { currency } = {}, discount: { amount_off } = {} } = {},
            } = {},
        } = product;

        const productName = listing_name || name;

        return (
            <div block="ProductListWithoutHotspotsWidget" elem="Product-Wrapper" key={uid}>
                {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
                <div
                    block="ProductListWithoutHotspotsWidget"
                    elem="Product"
                    onMouseOver={handleHoverEnable}
                    onMouseLeave={handleHoverDisable}
                >
                    <div block="ProductListWithoutHotspotsWidget" elem="Product-Content">
                        <Link to={url} aria-label={productName}>
                            <h3 block="ProductListWithoutHotspotsWidget" elem="Product-Name">
                                {productName}
                            </h3>
                        </Link>
                        {!isMobile && !isTablet ? (
                            <p block="ProductListWithoutHotspotsWidget" elem="Product-Description">
                                <Html content={short_description?.html} />
                            </p>
                        ) : null}
                        {amount_off > 0 ? (
                            <>
                                <div block="ProductListWithoutHotspotsWidget" elem="Product-Discount-Label">
                                    {__('You will save')}
                                </div>
                                <div block="ProductListWithoutHotspotsWidget" elem="Product-Discount-Value">
                                    -{formatPrice(amount_off, currency)}
                                </div>
                            </>
                        ) : null}
                    </div>
                    {this.renderGallery({
                        name: productName,
                        labels,
                        product,
                        url,
                    })}
                </div>
                {this.renderBuiltProductList(items)}
                {this.renderInformation(url)}
            </div>
        );
    }

    render() {
        const {
            products,
            isHover,
            currentSlide,
            setSwiperRef,
            handleSlideChange,
            handleHoverDisable,
            handleHoverEnable,
            handleNext,
            handlePrevious,
        } = this.props;

        if (!products?.length) {
            return (
                <div block="ProductListWithoutHotspotsWidget" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
            );
        }

        const slider = {
            slidesPerView: 1,
            spaceBetween: 32,
            on: {
                slideChange: handleSlideChange,
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'progressbar',
            },
        };

        return (
            <div block="ProductListWithoutHotspotsWidget">
                {this.renderHeader()}
                <div block="ProductListWithoutHotspotsWidget" elem="ProductList">
                    {this.renderSideNavigation({
                        isHover,
                        current: currentSlide,
                        totals: products?.length,
                        onHoverEnable: handleHoverEnable,
                        onHoverDisable: handleHoverDisable,
                        onPrevious: handlePrevious,
                        onNext: handleNext,
                    })}
                    <Swiper {...slider} ref={setSwiperRef}>
                        {products.map(this.renderProduct.bind(this))}
                    </Swiper>
                </div>
            </div>
        );
    }
}

export default ProductListWithoutHotspotsWidget;
