import { debounce } from 'lodash';
import React, { createRef } from 'react';
import { connect } from 'react-redux';

import ProductListQuery from 'Query/ProductList.query';
import { SORT_DIRECTION_TYPE } from 'Route/CategoryPage/CategoryPage.config';
import DataContainer from 'Util/Request/DataContainer';

import ProductListWithoutHotspotsWidget from './ProductListWithoutHotspotsWidget.component';

/** @namespace Pwa/Component/ProductListWithoutHotspotsWidget/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Pwa/Component/ProductListWithoutHotspotsWidget/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Pwa/Component/ProductListWithoutHotspotsWidget/Container/ProductListWithoutHotspotsWidgetContainer */
export class ProductListWithoutHotspotsWidgetContainer extends DataContainer {
    swiperRef = createRef();

    state = {
        products: [],
        currentSlide: 1,
        isHover: false,
    };

    handleHoverDisable = debounce(this._handleHoverDisable.bind(this), 500);

    containerFunctions = {
        handleHoverEnable: this.handleHoverEnable.bind(this),
        handleHoverDisable: this.handleHoverDisable,
        handleNext: this.handleNext.bind(this),
        handlePrevious: this.handlePrevious.bind(this),
        handleSlideChange: this.handleSlideChange.bind(this),
        setSwiperRef: this.setSwiperRef.bind(this),
    };

    setSwiperRef(element) {
        this.swiperRef.current = element;
    }

    handleHoverEnable() {
        this.setState(
            {
                isHover: true,
            },
            () => this.handleHoverDisable.cancel()
        );
    }

    _handleHoverDisable() {
        this.setState({
            isHover: false,
        });
    }

    handleNext() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.swiperRef.current.swiper.slideNext();
        }
    }

    handlePrevious() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.swiperRef.current.swiper.slidePrev();
        }
    }

    handleSlideChange() {
        if (this.swiperRef.current && this.swiperRef.current.swiper) {
            this.setState({
                currentSlide: this.swiperRef.current.swiper.realIndex + 1,
            });
        }
    }

    componentDidMount() {
        this.getProductsData();
    }

    containerProps() {
        const { title, device } = this.props;
        const { products, currentSlide, isHover } = this.state;

        return {
            title,
            products,
            device,
            currentSlide,
            isHover,
        };
    }

    getSortDirection(sortOrder) {
        if (!sortOrder) {
            return;
        }

        switch (sortOrder) {
            case 'position':
                return SORT_DIRECTION_TYPE.asc;
            case 'position_by_sku':
                return SORT_DIRECTION_TYPE.asc;
            case 'name_ascending':
                return SORT_DIRECTION_TYPE.asc;
            case 'name_descending':
                return SORT_DIRECTION_TYPE.desc;
            case 'price_high_to_low':
                return SORT_DIRECTION_TYPE.desc;
            case 'price_low_to_high':
                return SORT_DIRECTION_TYPE.asc;
            default:
                return undefined;
        }
    }

    getSortKey(sortOrder) {
        if (!sortOrder) {
            return;
        }

        switch (sortOrder) {
            case 'position':
                return 'position';
            case 'position_by_sku':
                return 'position_by_sku';
            case 'name_ascending':
                return 'name';
            case 'name_descending':
                return 'name';
            case 'price_high_to_low':
                return 'price';
            case 'price_low_to_high':
                return 'price';
            default:
                return undefined;
        }
    }

    getProductsData() {
        const { conditionsEncoded: conditions_encoded, productsCount, sortOrder } = this.props;

        const allowedSortOrders = [
            'position_by_sku',
            'position',
            'name_ascending',
            'name_descending',
            'price_high_to_low',
            'price_low_to_high',
        ];

        this.fetchData(
            [
                ProductListQuery.getQuery({
                    args: {
                        filter: {
                            conditions: conditions_encoded,
                            currentPage: 1,
                        },
                        pageSize: productsCount,
                        /* eslint-disable */
                        ...(allowedSortOrders.includes(sortOrder) ? {
                          sort: {
                              sortDirection: this.getSortDirection(sortOrder),
                              sortKey: this.getSortKey(sortOrder),
                          },
                        } : {}),
                        /* eslint-enable */
                    },
                    isArrangementWidget: true,
                    isPlp: true,
                    noAttributes: true,
                    isWithoutHotspotsWidget: true,
                }),
            ],
            ({ products } = {}) => {
                this.setState({ products: products?.items });
            }
        );
    }

    render() {
        return <ProductListWithoutHotspotsWidget {...this.containerFunctions} {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListWithoutHotspotsWidgetContainer);
